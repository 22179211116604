// Variáveis default do Redux


export default {
    defaultPath: '/home/',
    basename: '/', // only at build time to set, like /datta-able
    layout: 'vertical', // vertical, horizontal (not available in lite version)
    preLayout: null, // (not available in lite version)
    collapseMenu: false, // mini-menu
    layoutType: 'menu-light', // menu-dark, (menu-light, dark are not available in lite version)
    navIconColor: false,
    headerBackColor: 'header-default', // header-default, (header-blue, header-red, header-purple, header-lightblue, header-dark are not available in lite version)
    navBackColor: 'navbar-default', // navbar-default, (navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark are not available in lite version)
    navBrandColor: 'brand-default', // brand-default, (brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark are not available in lite version)
    navBackImage: false, // not available in lite version
    rtlLayout: false, // not available in lite version
    navFixedLayout: true,
    headerFixedLayout: true, // not available in lite version
    boxLayout: false,
    navDropdownIcon: 'style1', // style1, (style2, style3 are not available in lite version)
    navListIcon: 'style1', // style1, (style2, style3, style4, style5, style6 are not available in lite version)
    navActiveListColor: 'active-default', // active-default, (active-blue, active-red, active-purple, active-lightblue, active-dark are not available in lite version)
    navListTitleColor: 'title-default', // title-default, (title-blue, title-red, title-purple, title-lightblue, title-dark are not available in lite version)
    navListTitleHide: false, // not available in lite version
    configBlock: false, // not available in lite version
    layout6Background : 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // used only for pre-layout = layout-6
    layout6BackSize : '', // used only for pre-layout = layout-6

    // Controlará quando entrará a tela de loading no dashboard
    loadingIndicadores: false,

    // Controlará quando entrará a tela de loading no relatório detalhado
    loadingRelatorioDetalhado: false,

    PageloadVagas: false,

    // Chama quando a página inteira deve entrar em loading
    Pageload: true,

    // Local de oferta atualmente selecionada na aplicação
    localOfertaSelecionado: "",

    // Curso atualmente selecionado na aplicação
    cursoSelecionado: "",

    // Lista completa de cursos para o usuário escolher
    listaCursos: [],
    listaCursosUsuario: [],

    // Lista completa de polos para o usuário escolher
    listaLocalOferta: [],
    listaLocaisOfertaUsuario: [],
    listaLocaisOfertaUsuarioTodosCursos: [],
    
    // Lista de completa de cursos que dão match com a busca do usuário
    listaCursosFiltrado: false,

    
    // Lista de ocupações escolhidas pelo usuário
    ocupacoes: [],

    // Lista com os códigos das ocupações escolhidas pelo usuário
    codOcupacoes: [],

    // Lista completa de ocupações para o usuário escolher
    listaOcupacoes: [],

    // Lista de completa ocupações que dão match com a busca do usuário
    listaOcupacoesFiltrado: false,

    // Lista de completa ocupações do curso que dão match com a busca do usuário
    ocupacoesFiltrado: false,

    // Lista de ocupações salvas pelo usuário
    ocupacoesSaved: [],
    
    // Curso atualmente selecionado na aplicação
    instituicaoSelecionada: "",

    // Lista completa de cursos para o usuário escolher
    listaInstituicoes: [],
    listaInstituicoesUsuario: [],
    
    // Lista de completa de cursos que dão match com a busca do usuário
    listaInstituicoesFiltrado: false,

    // Lista completa de vagas do algoritmo de retirada de informação
    listaVagas: [],

    // Lista das requisições de vagas do usuário
    listaRequisicoesVagas: [],
    
    // Lista completa de cargos e locais do algoritmo de retirada de informação
    listaCargoLocal: [],

    // Lista de datas de extração de informação das vagas relacionadas ao cargoLocal atual
    listaVagasData: [],

    // cargo local selecionado do algoritmo de retirada de informação
    cargoLocalSelecionadoCombo: null,
   
    // Ano selecionado pelo usuário para ver os dados do dashboard
    anoSelecionado: "2019",

    // Lista com os anos disponíveis para escolha do usuário
    listaAnos: ['2015', '2016', '2017', '2018','2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026'],

    // Lista de estados
    listaEstados: [
        {
            "COD": "all",
            "NOME": "Todos os Estados",
            "SIGLA": "all"
        },
        {
            "_id": "606e6069ae85eb1f26261e97",
            "COD": "12",
            "NOME": "Acre",
            "SIGLA": "AC"
        },
        {
            "_id": "606e6069ae85eb1f26261ea1",
            "COD": "27",
            "NOME": "Alagoas",
            "SIGLA": "AL"
        },
        {
            "_id": "606e6069ae85eb1f26261e9b",
            "COD": "16",
            "NOME": "Amapá",
            "SIGLA": "AP"
        },
        {
            "_id": "606e6069ae85eb1f26261e98",
            "COD": "13",
            "NOME": "Amazonas",
            "SIGLA": "AM"
        },
        {
            "_id": "606e6069ae85eb1f26261ea3",
            "COD": "29",
            "NOME": "Bahia",
            "SIGLA": "BA"
        },
        {
            "_id": "606e6069ae85eb1f26261eaa",
            "COD": "23",
            "NOME": "Ceará",
            "SIGLA": "CE"
        },
        {
            "_id": "606e6069ae85eb1f26261ea8",
            "COD": "53",
            "NOME": "Distrito Federal",
            "SIGLA": "DF"
        },
        {
            "_id": "606e6069ae85eb1f26261eab",
            "COD": "32",
            "NOME": "Espírito Santo",
            "SIGLA": "ES"
        },
        {
            "_id": "606e6069ae85eb1f26261ea7",
            "COD": "52",
            "NOME": "Goiás",
            "SIGLA": "GO"
        },
        {
            "_id": "606e6069ae85eb1f26261e9d",
            "COD": "21",
            "NOME": "Maranhão",
            "SIGLA": "MA"
        },
        {
            "_id": "606e6069ae85eb1f26261ea6",
            "COD": "51",
            "NOME": "Mato Grosso",
            "SIGLA": "MT"
        },
        {
            "_id": "606e6069ae85eb1f26261e95",
            "COD": "50",
            "NOME": "Mato Grosso do Sul",
            "SIGLA": "MS"
        },
        {
            "_id": "606e6069ae85eb1f26261ea4",
            "COD": "31",
            "NOME": "Minas Gerais",
            "SIGLA": "MG"
        },
        {
            "_id": "606e6069ae85eb1f26261e92",
            "COD": "41",
            "NOME": "Paraná",
            "SIGLA": "PR"
        },
        {
            "_id": "606e6069ae85eb1f26261e9f",
            "COD": "25",
            "NOME": "Paraíba",
            "SIGLA": "PB"
        },
        {
            "_id": "606e6069ae85eb1f26261e9a",
            "COD": "15",
            "NOME": "Pará",
            "SIGLA": "PA"
        },
        {
            "_id": "606e6069ae85eb1f26261ea0",
            "COD": "26",
            "NOME": "Pernambuco",
            "SIGLA": "PE"
        },
        {
            "_id": "606e6069ae85eb1f26261ea9",
            "COD": "22",
            "NOME": "Piauí",
            "SIGLA": "PI"
        },
        {
            "_id": "606e6069ae85eb1f26261e9e",
            "COD": "24",
            "NOME": "Rio Grande do Norte",
            "SIGLA": "RN"
        },
        {
            "_id": "606e6069ae85eb1f26261e94",
            "COD": "43",
            "NOME": "Rio Grande do Sul",
            "SIGLA": "RS"
        },
        {
            "_id": "606e6069ae85eb1f26261ea5",
            "COD": "33",
            "NOME": "Rio de Janeiro",
            "SIGLA": "RJ"
        },
        {
            "_id": "606e6069ae85eb1f26261e96",
            "COD": "11",
            "NOME": "Rondônia",
            "SIGLA": "RO"
        },
        {
            "_id": "606e6069ae85eb1f26261e99",
            "COD": "14",
            "NOME": "Roraima",
            "SIGLA": "RR"
        },
        {
            "_id": "606e6069ae85eb1f26261e93",
            "COD": "42",
            "NOME": "Santa Catarina",
            "SIGLA": "SC"
        },
        {
            "_id": "606e6069ae85eb1f26261ea2",
            "COD": "28",
            "NOME": "Sergipe",
            "SIGLA": "SE"
        },
        {
            "_id": "606e6069ae85eb1f26261e91",
            "COD": "35",
            "NOME": "São Paulo",
            "SIGLA": "SP"
        },
        {
            "_id": "606e6069ae85eb1f26261e9c",
            "COD": "17",
            "NOME": "Tocantins",
            "SIGLA": "TO"
        }
    ],

    grausEscolaridade : [
        {value: 1, label: "Analfabeto"},
        {value: 2, label: "Até o 5º ano do Fundamental Incompleto"},
        {value: 3, label: "Até o 5º ano do Fundamental Completo"},
        {value: 4, label: "6º a 9º ano do Fundamental"},
        {value: 5, label: "Fundamental Completo"},
        {value: 6, label: "Ensino Médio Incompleto"},
        {value: 7, label: "Ensino Médio Completo"},
        {value: 8, label: "Ensino Superior Incompleto"},
        {value: 9, label: "Ensino Superior Completo"},
        {value: 10, label: "Mestrado"},
        {value: 11, label: "Doutorado"}
    ],

    estadoSelecionado: "all",
    estadoSelecionadoCombo: null,
    estadoSelecionadoConcorrentes: {cod: "all", sigla: ""},
    estadoSelecionadoComboConcorrentes: null,

    listaMunicipios: [],
    listaMunicipiosConcorrentes: [],

    municipioSelecionado: "all",
    municipioSelecionadoCombo: null,
    municipioSelecionadoConcorrentes: "all",
    municipioSelecionadoComboConcorrentes: null,

    ocupacaoSelecionada: "all_selected",
    ocupacaoSelecionadaCombo: {value: "all_selected", label: "Todas as Ocupações Selecionadas"},
    concorrenteSelecionado: "all",
    concorrenteSelecionadoCombo: {value: "all", label: "Todos os Concorrentes da Região"},

    grauEscolaridadeSelecionado: "all",
    grauEscolaridadeSelecionadoCombo: {value: "all", label: "Todos os Graus de Escolaridade"},

    // Lista de indicadores
    indicadores: {},
    indicadoresIES: {},
    indicadoresConcorrentes: {},
    indicadoresROI: {},

    //Lista ENADE
    indicadoresEnade: [],

    // Custo total do curso
    custoTotal: {
        formatted: 'R$ -',
        raw: null,
        periodo: {tipo: "anos", valor: 4}
    },

    // Lista de relatorio detalhado
    relatorioDetalhado: {},

    isWizard: true,

    atualizacoes: [],

    isLogedIn: false,

    redirectWithUserSavedState: true,

    isEditing: false,

    listaConcorrentes: [],

    concorrentes: [],

    tipoDashboard: "Empregabilidade",

    erroConexao: false,

    loadDataDashboardEmpregabilidade: true,
    loadDataDashboardInteligenciaCompetitiva: true,
    loadDataRelatorioDetalhado: true,
    loadDataResumo: true,

    userSavedStates: [],

    exibirBotaoVoltarGerenciarCursos: false,
    
    namesCollections: {},

    usuarios: [],

    perfis: [],

    usuarioSelecionado: {},

    instituicaoUsuarioSelecionada: {},

    localOfertaUsuarioSelecionado: {},

    cursoUsuarioSelecionado: {},

    isSuperAdmin: false,

    databaseCreateOrUpdateSuccessStatus: false,

    requisicaoVagasSuccessStatus: "",
    
    filterShow: false,
    
    infoMunicipios: [],

    areaDetalhadaSelecionadoCombo: false,

    dataSelecionadoCombo: {value: null, label: "Todas as datas disponíveis"},

    dataCapturadaCombo: {value: null, label: "Todas as datas disponíveis"},

    ocupacaoCapturadaCombo: {value: "all_selected", label: "Todas as Ocupações Selecionadas"},

    estadoCapturadoCombo: null,

    municipioCapturadoCombo: null,

    cancelarWizard: false
};
