import { axios, store, removeLoginIfLogedOut } from '../config/axios-config'
import { useQuery } from 'react-query'
import { defaultQueryConfig } from '../config/query-config'

export async function fetchInfoMunicipios() {
    store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: false })

    try {
        const { data } = await axios.get('/backend/listaInfoMunicipios')
        store.dispatch({ type: 'SET_INFO_MUNICIPIOS', list: data })
        return data
    } catch (error) {
        removeLoginIfLogedOut(error)
        store.dispatch({ type: 'SET_LOADING', loading: false })
        store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: true })
        throw error
    }
}

export default function useInfoMunicipios() {
    return useQuery('infoMunicipios',
        fetchInfoMunicipios,
        defaultQueryConfig)
}
