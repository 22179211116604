import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";

import { QueryClient, QueryClientProvider } from 'react-query'; // Se você usa @tanstack/react-query, atualize aqui
// import { persistQueryClient } from 'react-query-persist-client';
// import { createLocalStoragePersistor } from 'react-query-persist-client';
import { queryClient } from '../connection/config/react-query-client'

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

// Set up a persister using local storage

// const queryClient = new QueryClient();

// const localStoragePersistor = createLocalStoragePersistor();
//
// // Setup persistence for query client
// persistQueryClient({
//     queryClient,
//     persistor: localStoragePersistor,
//     maxAge: 24 * 60 * 60 * 1000, // Retain data for 24 hours
// });

class App extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        
        const menu = routes.map((route, index) => {
          return (route.component) ? (
              <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                      <route.component {...props} />
                  )} />
          ) : (null);
        });

        return (
            <QueryClientProvider client={queryClient}>
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                            {menu}
                            <Route path="/" component={AdminLayout} />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
            </QueryClientProvider>
        );
    }
}

const mapStateToProps = state => {
    return {
        Pageload: state.Pageload
    }
}
    

export default connect(mapStateToProps)(App)
