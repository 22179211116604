import { axios, store, removeLoginIfLogedOut, abortController } from './config/axios-config'

const getUsuarios = async () => {

    setTimeout(() =>{store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: false })}, 0)
    
    try {
        const response = await axios.get('/user/getUsuarios',{
            cancelToken: abortController.token
        })
        
        if(!Object.keys(response.data).includes('noPermisson')){
            store.dispatch({ type: 'SET_USUARIOS', list: response.data  })
        }
        
    } catch (error) {
        removeLoginIfLogedOut(error)
        store.dispatch({ type: 'SET_LOADING', loading: false })
        store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: true })
    }
}

export default getUsuarios