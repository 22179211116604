import * as actionTypes from './actions'
import initialState from './initialState'

// Responsável por alterar o menu
import getMenuItems from './menuItems'

// Funções de busca de dados
import getAllData from '../connection/getAllData'
import localStorage from './localStorage'

const reducer = (state = initialState, action) => {
    let trigger = [];
    let open = [];

    switch (action.type) {
        case actionTypes.COLLAPSE_MENU:
            return {
                ...state,
                collapseMenu: !state.collapseMenu
            };
        case actionTypes.COLLAPSE_TOGGLE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }

                if (triggerIndex === -1) {
                    open = [...open, action.menu.id];
                    trigger = [...trigger, action.menu.id];
                }
            } else {
                open = state.isOpen;
                const triggerIndex = (state.isTrigger).indexOf(action.menu.id);
                trigger = (triggerIndex === -1) ? [action.menu.id] : [];
                open = (triggerIndex === -1) ? [action.menu.id] : [];
            }

            return {
                ...state,
                isOpen: open,
                isTrigger: trigger
            };
        case actionTypes.NAV_CONTENT_LEAVE:
            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case actionTypes.NAV_COLLAPSE_LEAVE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }
                return {
                    ...state,
                    isOpen: open,
                    isTrigger: trigger,
                };
            }
            return {...state};
        case actionTypes.FULL_SCREEN :
            return {
                ...state,
                isFullScreen: !state.isFullScreen
            };
        case actionTypes.SAVE_REDUX:
            localStorage.saveState(state);
            return state;

        case actionTypes.LOAD_REDUX:
            const stateMerged = {
                ...state,
                ...action.value
                //Pageload: true
            }
            // getAllData(stateMerged, false)
            return stateMerged;    
        
        case actionTypes.USER_IS_EDITING:
            return {
                ...state,
                isEditing: action.value
            };
        
        case actionTypes.FULL_SCREEN_EXIT:
            return {
                ...state,
                isFullScreen: false
            };
        case actionTypes.CHANGE_LAYOUT:
            return {
                ...state,
                layout: action.layout
            };
        case actionTypes.SELECT_LOCAL_OFERTA:
            return {
                ...state,
                localOfertaSelecionado: action.item,
                cursoSelecionado: state?.localOfertaSelecionado?.CO_LOCAL_OFERTA === action.item?.CO_LOCAL_OFERTA ? state.cursoSelecionado:"",
                concorrenteSelecionado: state?.localOfertaSelecionado?.CO_LOCAL_OFERTA === action.item?.CO_LOCAL_OFERTA ? state.concorrenteSelecionado:"all_selected",
                concorrenteSelecionadoCombo: state?.localOfertaSelecionado?.CO_LOCAL_OFERTA === action.item?.CO_LOCAL_OFERTA ? state.concorrenteSelecionadoCombo:{value: "all_selected", label: "Todos os Concorrentes da Região"},
                concorrentes: state?.localOfertaSelecionado?.CO_LOCAL_OFERTA === action.item?.CO_LOCAL_OFERTA ? state.concorrentes:[],
            }
        case actionTypes.SELECT_COURSE:
            // Acrescenta os dados do curso selecionado no Redux
            // getIESIndicadores(
            //     {
            //         anoSelecionado: state.anoSelecionado,
            //         estadoSelecionado: state.estadoSelecionado,
            //         municipioSelecionado: state.municipioSelecionado,
            //         cursoSelecionado: action.title,
            //         instituicaoSelecionada: state.instituicaoSelecionada
            //     }
            // )

            // getOcupationData(action.title)
            
            const selectedCourse = {
                ...state,
                cursoSelecionado: action.title,
                concorrenteSelecionado: state?.cursoSelecionado?.CO_CURSO_LABORE_IES === action.title?.CO_CURSO_LABORE_IES ? state.concorrenteSelecionado:"all",
                concorrenteSelecionadoCombo: state?.cursoSelecionado?.CO_CURSO_LABORE_IES === action.title?.CO_CURSO_LABORE_IES ? state.concorrenteSelecionadoCombo:{value: "all", label: "Todos os Concorrentes da Região"},
                concorrentes: state?.cursoSelecionado?.CO_CURSO_LABORE_IES === action.title?.CO_CURSO_LABORE_IES ? state.concorrentes:[],
                menu: getMenuItems(action.title)
                // ocupacoes: [],
                // codOcupacoes: []
            }
            if (action.saveState == false) localStorage.saveState(selectedCourse, false) 
                else localStorage.saveState(selectedCourse)
            return selectedCourse
        case actionTypes.FILTER_COURSE:
            return {
                ...state,
                listaCursosFiltrado: action.filtered
            }
        case actionTypes.SELECT_IES:
            // Acrescenta os dados do curso selecionado no Redux
            // if(state.cursoSelecionado){
            //     getIESIndicadores(
            //         {
            //             anoSelecionado: state.anoSelecionado,
            //             estadoSelecionado: state.estadoSelecionado,
            //             municipioSelecionado: state.municipioSelecionado,
            //             cursoSelecionado: state.cursoSelecionado,
            //             instituicaoSelecionada: action.title
            //         }
            //     )
            // }

            

            const retorno = {
                ...state,
                // estadoSelecionado: {cod: objetoEstadoIES.COD, sigla: objetoEstadoIES.SIGLA},
                // estadoSelecionadoCombo: {value: {cod: objetoEstadoIES.COD, sigla: objetoEstadoIES.SIGLA}, label: objetoEstadoIES.NOME},
                // municipioSelecionado: ,
                // municipioSelecionadoCombo: ,
                instituicaoSelecionada: action.title,
                cursoSelecionado: state?.instituicaoSelecionada?.CO_IES === action.item?.CO_IES ? state.cursoSelecionado:"",
                localOfertaSelecionado: state?.instituicaoSelecionada?.CO_IES === action.item?.CO_IES ? state.localOfertaSelecionado:"",
                concorrenteSelecionado: state?.instituicaoSelecionada?.CO_IES === action.item?.CO_IES ? state.concorrenteSelecionado:"all",
                concorrenteSelecionadoCombo: state?.instituicaoSelecionada?.CO_IES === action.item?.CO_IES ? state.concorrenteSelecionadoCombo:{value: "all", label: "Todos os Concorrentes da Região"},
                concorrentes: state?.instituicaoSelecionada?.CO_IES === action.item?.CO_IES ? state.concorrentes:[],
            }
            if (action.saveState == false) localStorage.saveState(retorno, false) 
                else localStorage.saveState(retorno)
            return retorno
        case actionTypes.FILTER_IES:
            return {
                ...state,
                listaInstituicoesFiltrado: action.filtered
            }
        case actionTypes.GET_LIST_COURSES:
            return {    
                ...state,
                listaCursos: action.list
            }
        case actionTypes.GET_LIST_USER_COURSES:
            return {    
                ...state,
                listaCursosUsuario: action.list
            }
        case actionTypes.GET_LIST_VAGAS:
            return {    
                ...state,
                listaVagas: action.list
            }
        case actionTypes.SET_LIST_REQUISICAO_VAGAS:
            return {    
                ...state,
                listaRequisicoesVagas: action.list
            }
        case actionTypes.GET_CARGO_LOCAL:
            return {    
                ...state,
                listaCargoLocal: action.list
            }
        case actionTypes.SET_CARGO_LOCAL:
            return {    
                ...state,
                cargoLocalSelecionadoCombo: action.value
            }
        case actionTypes.GET_VAGAS_DATA:
            return {    
                ...state,
                listaVagasData: action.list
            }
        case actionTypes.SET_AREA_DETALHADA_SELECIONADA:
            return {    
                ...state,
                areaDetalhadaSelecionadoCombo: action.value
            }
        case actionTypes.SET_USUARIOS:
            return {
                ...state,
                usuarios: action.list
            }
        case actionTypes.SET_PERFIS:
            return {
                ...state,
                perfis: action.list
            }
        case actionTypes.SET_USUARIO_SELECIONADO:
            return {
                ...state,
                usuarioSelecionado: action.value
            }
        case actionTypes.SET_IES_USUARIO_SELECIONADA:
            return {
                ...state,
                instituicaoUsuarioSelecionada: action.value
            }
        case actionTypes.SET_LOCAL_OFERTA_USUARIO_SELECIONADO:
            return {
                ...state,
                localOfertaUsuarioSelecionado: action.value
            }
        case actionTypes.SET_CURSO_USUARIO_SELECIONADO:
            return {
                ...state,
                cursoUsuarioSelecionado: action.value
            }
        case actionTypes.GET_LIST_LOCAL_OFERTA:
            return {
                ...state,
                listaLocalOferta: action.list
            }
        case actionTypes.SET_DATABASE_CREATE_UPDATE_SUCCESS_STATUS:
            return {
                ...state,
                databaseCreateOrUpdateSuccessStatus: action.value
            }
        case actionTypes.SET_REQUISICAO_VAGAS_STATUS:
            return {
                ...state,
                requisicaoVagasSuccessStatus: action.value
            }
        case actionTypes.SET_FILTER_SHOW:
            return {
                ...state,
                filterShow: action.value
            }
        case actionTypes.GET_LIST_LOCAL_OFERTA_USUARIO:
            return {
                ...state,
                listaLocaisOfertaUsuario: action.list
            }
        case actionTypes.GET_LIST_LOCAL_OFERTA_USUARIO_TODOS_CURSOS:
            return {
                ...state,
                listaLocaisOfertaUsuarioTodosCursos: action.list
            }
        case actionTypes.GET_LIST_OCUPATIONS:
            return {
                ...state,
                listaOcupacoes: action.list
            }
        case actionTypes.SET_INFO_MUNICIPIOS:
            return {
                ...state,
                infoMunicipios: action.list
            }
        case actionTypes.SAVE_OCUPATION:
            const codOcupacoes = []
            action.list.forEach((ocupacao) => codOcupacoes.push(ocupacao.NU_CODIGO_CBO))

            const newOcupacoesSaved = {
                ...state,
                codOcupacoes,
                ocupacoes: action.list,
                ocupacoesSaved: action.list,
                ocupacaoSelecionadaCombo: {value: "all_selected", label: "Todas as Ocupações Selecionadas"}
            }
            if (action.saveState == false) localStorage.saveState(newOcupacoesSaved, false) 
                else localStorage.saveState(newOcupacoesSaved)
            //getIndicadores(newOcupacoesSaved)
            return newOcupacoesSaved
        case actionTypes.DISCARD_OCUPATION:
            return {
                ...state,
                ocupacoes: state.ocupacoesSaved
            }
        case actionTypes.CHANGE_YEAR:
            const newAno = {
                ...state,
                anoSelecionado: action.yearObj.year
            }
            const getAllDataObjectChangeYear = {
                anoSelecionado: action.yearObj.year, 
                codOcupacoes: state.codOcupacoes, 
                estadoSelecionado: state.estadoSelecionado, 
                municipioSelecionado: state.municipioSelecionado, 
                cursoSelecionado: state.cursoSelecionado,
                concorrenteSelecionado: state.concorrenteSelecionado,
                instituicaoSelecionada: state.instituicaoSelecionada
            }
            getAllData(getAllDataObjectChangeYear, true, false, action.yearObj.tipoChamada, newAno, state.areaDetalhadaSelecionadoCombo)
            localStorage.saveState(newAno)
            return newAno
        // Adiciona o estado selecionado ao Redux
        case actionTypes.SET_ESTADO_SELECIONADO:
            const newIndicadores = {
                ...state,
                estadoSelecionado: action.estado.value,
                estadoSelecionadoCombo: action.estado,
                municipioSelecionadoCombo: {value: "all", label: "Todos os Municípios"},
                municipioSelecionado: "all"
            }
            if (!state.isWizard){
                if((state.cursoSelecionado != '')&&((action.saveState === undefined ? true : action.saveState))) localStorage.saveState(newIndicadores)
                if (!state.isEditing) {
                    const getAllDataObjectSetEstadoSelecionado = {
                        anoSelecionado: state.anoSelecionado, 
                        codOcupacoes: state.codOcupacoes, 
                        estadoSelecionado: action.estado.value, 
                        municipioSelecionado: "all", 
                        cursoSelecionado: state.cursoSelecionado
                    }
                    if(state.cursoSelecionado != '') getAllData(getAllDataObjectSetEstadoSelecionado, false, false, 'Empregabilidade', newIndicadores, state.areaDetalhadaSelecionadoCombo)
                }
            } 
            if (action.saveState === undefined ? true : action.saveState) localStorage.saveState(newIndicadores)
            return newIndicadores
        case actionTypes.SET_ESTADO_SELECIONADO_CONCORRENTES:
            const newEstadoConcorrentes = {
                ...state,
                estadoSelecionadoConcorrentes: action.estado.value,
                estadoSelecionadoComboConcorrentes: action.estado,
                municipioSelecionadoComboConcorrentes: {value: "all", label: "Todos os Municípios"},
                municipioSelecionadoConcorrentes: "all"
            }
            if (action.saveState === undefined ? true : action.saveState) localStorage.saveState(newEstadoConcorrentes)
            return newEstadoConcorrentes
        // Adiciona a lista de estados ao Redux
        case actionTypes.SET_MUNICIPIOS:
            return {
                ...state,
                listaMunicipios: action.list
            }
        case actionTypes.SET_MUNICIPIOS_CONCORRENTES:
            return {
                ...state,
                listaMunicipiosConcorrentes: action.list
            }
        case actionTypes.SET_INDICADORES:
            return {
                ...state,
                indicadores: action.list
                //loadingIndicadores: false
            }
        // Adiciona o município selecionado ao Redux
        case actionTypes.SET_MUNICIPIO_SELECIONADO:
            const newMunicipio = {
                ...state,
                municipioSelecionado: action.value.value,
                municipioSelecionadoCombo: action.value
            }
            if (!state.isWizard) {
                if((state.cursoSelecionado != '')&&(action.saveState === undefined ? true : action.saveState)) localStorage.saveState(newMunicipio)
                if (!state.isEditing) {
                    const getAllDataObjectSetMunicipioSelecionado = {
                        anoSelecionado: state.anoSelecionado, 
                        codOcupacoes: state.codOcupacoes, 
                        estadoSelecionado: state.estadoSelecionado, 
                        municipioSelecionado: action.value.value, 
                        cursoSelecionado: state.cursoSelecionado
                    }
                    if(state.cursoSelecionado != '') getAllData(getAllDataObjectSetMunicipioSelecionado, false, false, "Empregabilidade", false, state.areaDetalhadaSelecionadoCombo)
                }
            }
            if (action.saveState === undefined ? true : action.saveState) localStorage.saveState(newMunicipio)
            return newMunicipio
        case actionTypes.SET_GRAU_ESCOLARIDADE_SELECIONADO:
            const newGrauEscolaridade = {
                ...state,
                grauEscolaridadeSelecionado: action.value.value,
                grauEscolaridadeSelecionadoCombo: action.value
            }
            
            localStorage.saveState(newGrauEscolaridade)
            return newGrauEscolaridade
        case actionTypes.SET_MUNICIPIO_SELECIONADO_CONCORRENTES:
            const newMunicipioConcorrentes = {
                ...state,
                municipioSelecionadoConcorrentes: action.value.value,
                municipioSelecionadoComboConcorrentes: action.value
            }
            if (action.saveState === undefined ? true : action.saveState) localStorage.saveState(newMunicipioConcorrentes)
            return newMunicipioConcorrentes
        case actionTypes.SET_OCUPACAO_SELECIONADA:
            let codOcupacoesSelecionadas = []
            state.ocupacoes.map((e)=>{
                codOcupacoesSelecionadas.push(e.NU_CODIGO_CBO)
            })

            const newOcupacao = {
                ...state,
                codOcupacoes: action.value.value === "all_selected"? codOcupacoesSelecionadas : [action.value.value],
                ocupacaoSelecionadaCombo: action.value,
                ocupacaoSelecionada: action.value.value
            }

            const getAllDataObjectSetOcupacaoSelecionada = {
                anoSelecionado: state.anoSelecionado, 
                codOcupacoes: action.value.value === "all_selected"? codOcupacoesSelecionadas : [action.value.value], 
                estadoSelecionado: state.estadoSelecionado, 
                municipioSelecionado: state.municipioSelecionado, 
                cursoSelecionado: state.cursoSelecionado
            }
            getAllData(getAllDataObjectSetOcupacaoSelecionada, true, false, action.tipoChamada, newOcupacao, state.areaDetalhadaSelecionadoCombo)
            localStorage.saveState(newOcupacao)
            
            return {
                ...state, 
                codOcupacoes: state.codOcupacoes,
                ocupacaoSelecionadaCombo: action.value,
                ocupacaoSelecionada: action.value.value
            }
        case actionTypes.SET_CONCORRENTE_SELECIONADO:
            let concorrenteBusca = []
            concorrenteBusca.push(state.concorrentes.find(o => o.CO_IES === action.value.value))
            if(action.value.value === "all"){
                concorrenteBusca = "all"
            } else if(action.value.value === "all_selected") {
                concorrenteBusca = state.concorrentes
            }
            const newConcorrente = {
                ...state,
                concorrenteSelecionadoCombo: action.value,
                concorrenteSelecionado: concorrenteBusca
            }
            localStorage.saveState(newConcorrente)
            return newConcorrente
        case actionTypes.SET_LOADING:
            return {
                ...state,
                loadingIndicadores: action.loading,
                loadingRelatorioDetalhado: action.loading
            }    
        case actionTypes.SET_PAGELOAD:
            return {
                ...state,
                Pageload: action.load,
                StartLoading: action.load
            }
        case actionTypes.SET_IES_LIST:
            return {
                ...state,
                listaInstituicoes: action.list
            }
        case actionTypes.SET_USER_IES_LIST:
            return {
                ...state,
                listaInstituicoesUsuario: action.list
            }
        case actionTypes.SET_LISTA_CONCORRENTES:
            return {
                ...state,
                listaConcorrentes: action.list
            }
        case actionTypes.SET_CONCORRENTES:
            return {
                ...state,
                concorrentes: action.list
            }
        case actionTypes.SET_IES_INDICADORES:
            return {
                ...state,
                indicadoresIES: action.list,
            }
        case actionTypes.SET_ENADE_LISTA:
            return {
                ...state,
                indicadoresEnade: action.list,
            }
        case actionTypes.SET_CONCORRENTES_INDICADORES:
            const concorrentesIndicadores =  {
                ...state,
                indicadoresConcorrentes: action.list,
            }
            localStorage.saveState(concorrentesIndicadores)
            return {
                ...state,
                indicadoresConcorrentes: action.list,
            }
        case actionTypes.SAVE_COST:
            // if(action.cost.raw && state.indicadores?.indicadorSalarioMedio){
            //     getIndicadoresROI({
            //         salarioGraduacao: state.indicadores.indicadorSalarioMedio.mediaGraduados,
            //         salarioMedio: state.indicadores.indicadorSalarioMedio.mediaOcupacao,
            //         salarioEnsinoMedio: state.indicadores.indicadorSalarioMedio.mediaEnsinoMedio,
            //         custo: action.cost.raw,
            //         periodo: action.cost.periodo
            //     })
            // }

            const custoTotalSalvo = {
                ...state,
                custoTotal: action.cost
            }
            if (action.saveState == false) localStorage.saveState(custoTotalSalvo, false) 
                else localStorage.saveState(custoTotalSalvo)
            return custoTotalSalvo
        case actionTypes.SET_ROI:
            return {
                ...state,
                indicadoresROI: action.list
            }
        case actionTypes.SET_WIZARD:
            console.log(`Wizard foi setado para ${action.item}`)
            return {
                ...state,
                isWizard: action.item
            }
        case actionTypes.SET_STATUS_ERRO_CONEXAO:
            return {
                ...state,
                erroConexao: action.value
            }
        case actionTypes.SET_RELATORIO_DETALHADO:
            action.list.indicadoresIES = state.relatorioDetalhado.indicadoresIES
            return {
                ...state,
                relatorioDetalhado: action.list,
            }
        case actionTypes.SET_RELATORIO_DETALHADO_IES:
            return {
                ...state,
                relatorioDetalhado: {
                    ...state.relatorioDetalhado,
                    indicadoresIES: action.list
                }
                //loadingRelatorioDetalhado: false
            }
        case actionTypes.SET_RANKING_AUTOMACAO:
            return {
                ...state,
                relatorioDetalhado: {
                    ...state.relatorioDetalhado,
                    indicadorRankingAutomacao: action.list.indicadorRankingAutomacao
                }
            }
        case actionTypes.SET_LOAD_DATA_DASHBOARD_EMPREGABILIDADE:
            return {
                ...state,
                loadDataDashboardEmpregabilidade: action.value
            }
        case actionTypes.SET_LOAD_DATA_DASHBOARD_INTELIGENCIA_COMPETITIVA:
            return {
                ...state,
                loadDataDashboardInteligenciaCompetitiva: action.value
            }
        case actionTypes.SET_LOAD_DATA_DASHBOARD_RELATORIO_DETALHADO:
            return {
                ...state,
                loadDataRelatorioDetalhado: action.value
            }
        case actionTypes.SET_LOAD_DATA_RESUMO:
        return {
            ...state,
            loadDataResumo: action.value
        }
        case actionTypes.RESTORE_REDUX:
            // config.js
            return {
                ...state,
                defaultPath: '/home/',
                basename: '/', // only at build time to set, like /datta-able
                layout: 'vertical', // vertical, horizontal (not available in lite version)
                preLayout: null, // (not available in lite version)
                collapseMenu: false, // mini-menu
                layoutType: 'menu-light', // menu-dark, (menu-light, dark are not available in lite version)
                navIconColor: false,
                headerBackColor: 'header-default', // header-default, (header-blue, header-red, header-purple, header-lightblue, header-dark are not available in lite version)
                navBackColor: 'navbar-default', // navbar-default, (navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark are not available in lite version)
                navBrandColor: 'brand-default', // brand-default, (brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark are not available in lite version)
                navBackImage: false, // not available in lite version
                rtlLayout: false, // not available in lite version
                navFixedLayout: true,
                headerFixedLayout: true, // not available in lite version
                boxLayout: false,
                navDropdownIcon: 'style1', // style1, (style2, style3 are not available in lite version)
                navListIcon: 'style1', // style1, (style2, style3, style4, style5, style6 are not available in lite version)
                navActiveListColor: 'active-default', // active-default, (active-blue, active-red, active-purple, active-lightblue, active-dark are not available in lite version)
                navListTitleColor: 'title-default', // title-default, (title-blue, title-red, title-purple, title-lightblue, title-dark are not available in lite version)
                navListTitleHide: false, // not available in lite version
                configBlock: false, // not available in lite version
                layout6Background : 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // used only for pre-layout = layout-6
                layout6BackSize : '', // used only for pre-layout = layout-6

                // Controlará quando entrará a tela de loading no dashboard
                loadingIndicadores: false,

                // Controlará quando entrará a tela de loading no relatório detalhado
                loadingRelatorioDetalhado: false,

                // Curso atualmente selecionado na aplicação
                cursoSelecionado: "",

                // Lista completa de cursos para o usuário escolher
                listaCursos: [],
                
                // Lista de completa de cursos que dão match com a busca do usuário
                listaCursosFiltrado: false,

                
                // Lista de ocupações escolhidas pelo usuário
                ocupacoes: [],

                // Lista com os códigos das ocupações escolhidas pelo usuário
                codOcupacoes: [],

                // Lista completa de ocupações para o usuário escolher
                listaOcupacoes: [],

                // Lista de completa ocupações que dão match com a busca do usuário
                listaOcupacoesFiltrado: false,

                // Lista de completa ocupações do curso que dão match com a busca do usuário
                ocupacoesFiltrado: false,

                // Lista de ocupações salvas pelo usuário
                ocupacoesSaved: [],

                dataSelecionadoCombo: {value: null, label: "Todas as datas disponíveis"},
                
                dataCapturadaCombo: {value: null, label: "Todas as datas disponíveis"},

                ocupacaoCapturadaCombo: {value: "all_selected", label: "Todas as Ocupações Selecionadas"},

                estadoCapturadoCombo: null,

                municipioCapturadoCombo: null,
                
                // Curso atualmente selecionado na aplicação
                instituicaoSelecionada: "",

                // Lista completa de cursos para o usuário escolher
                listaInstituicoes: [],
                
                // Lista de completa de cursos que dão match com a busca do usuário
                listaInstituicoesFiltrado: false,

                // Lista completa de vagas do algoritmo de retirada de informação
                listaVagas: [],

                // Lista das requisições de vagas do usuário
                listaRequisicoesVagas: [],

                // Lista completa de cargos e locais do algoritmo de retirada de informação
                listaCargoLocal: [],

                // Lista de datas de extração de informação das vagas relacionadas ao cargoLocal atual
                listaVagasData: [],
                
                // Ano selecionado pelo usuário para ver os dados do dashboard
                anoSelecionado: "2019",

                // Lista com os anos disponíveis para escolha do usuário
                listaAnos: ['2015', '2016', '2017', '2018','2019', '2020', '2021', '2022', '2023', '2024'],

                // Lista de estados
                listaEstados: [
                    {
                        "COD": "all",
                        "NOME": "Todos os Estados",
                        "SIGLA": "all"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261e97",
                        "COD": "12",
                        "NOME": "Acre",
                        "SIGLA": "AC"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261ea1",
                        "COD": "27",
                        "NOME": "Alagoas",
                        "SIGLA": "AL"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261e9b",
                        "COD": "16",
                        "NOME": "Amapá",
                        "SIGLA": "AP"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261e98",
                        "COD": "13",
                        "NOME": "Amazonas",
                        "SIGLA": "AM"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261ea3",
                        "COD": "29",
                        "NOME": "Bahia",
                        "SIGLA": "BA"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261eaa",
                        "COD": "23",
                        "NOME": "Ceará",
                        "SIGLA": "CE"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261ea8",
                        "COD": "53",
                        "NOME": "Distrito Federal",
                        "SIGLA": "DF"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261eab",
                        "COD": "32",
                        "NOME": "Espírito Santo",
                        "SIGLA": "ES"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261ea7",
                        "COD": "52",
                        "NOME": "Goiás",
                        "SIGLA": "GO"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261e9d",
                        "COD": "21",
                        "NOME": "Maranhão",
                        "SIGLA": "MA"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261ea6",
                        "COD": "51",
                        "NOME": "Mato Grosso",
                        "SIGLA": "MT"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261e95",
                        "COD": "50",
                        "NOME": "Mato Grosso do Sul",
                        "SIGLA": "MS"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261ea4",
                        "COD": "31",
                        "NOME": "Minas Gerais",
                        "SIGLA": "MG"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261e92",
                        "COD": "41",
                        "NOME": "Paraná",
                        "SIGLA": "PR"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261e9f",
                        "COD": "25",
                        "NOME": "Paraíba",
                        "SIGLA": "PB"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261e9a",
                        "COD": "15",
                        "NOME": "Pará",
                        "SIGLA": "PA"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261ea0",
                        "COD": "26",
                        "NOME": "Pernambuco",
                        "SIGLA": "PE"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261ea9",
                        "COD": "22",
                        "NOME": "Piauí",
                        "SIGLA": "PI"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261e9e",
                        "COD": "24",
                        "NOME": "Rio Grande do Norte",
                        "SIGLA": "RN"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261e94",
                        "COD": "43",
                        "NOME": "Rio Grande do Sul",
                        "SIGLA": "RS"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261ea5",
                        "COD": "33",
                        "NOME": "Rio de Janeiro",
                        "SIGLA": "RJ"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261e96",
                        "COD": "11",
                        "NOME": "Rondônia",
                        "SIGLA": "RO"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261e99",
                        "COD": "14",
                        "NOME": "Roraima",
                        "SIGLA": "RR"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261e93",
                        "COD": "42",
                        "NOME": "Santa Catarina",
                        "SIGLA": "SC"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261ea2",
                        "COD": "28",
                        "NOME": "Sergipe",
                        "SIGLA": "SE"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261e91",
                        "COD": "35",
                        "NOME": "São Paulo",
                        "SIGLA": "SP"
                    },
                    {
                        "_id": "606e6069ae85eb1f26261e9c",
                        "COD": "17",
                        "NOME": "Tocantins",
                        "SIGLA": "TO"
                    }
                ],
                estadoSelecionado: "all",
                estadoSelecionadoCombo: null,
                estadoSelecionadoConcorrentes: {cod: "all", sigla: ""},
                estadoSelecionadoComboConcorrentes: null,

                listaMunicipios: [],
                listaMunicipiosConcorrentes: [],

                municipioSelecionado: "all",
                municipioSelecionadoCombo: null,
                municipioSelecionadoConcorrentes: "all",
                municipioSelecionadoComboConcorrentes: null,

                ocupacaoSelecionada: "all_selected",
                ocupacaoSelecionadaCombo: {value: "all_selected", label: "Todas as Ocupações Selecionadas"},

                // Lista de indicadores
                indicadores: {},
                indicadoresIES: {},
                indicadoresROI: {},

                //Lista ENADE
                indicadoresEnade: [],
                
                // Custo total do curso
                custoTotal: {
                    formatted: 'R$ -',
                    raw: null,
                    periodo: {tipo: "anos", valor: 4}
                },

                // Lista de relatorio detalhado
                relatorioDetalhado: {},

                isWizard: true,

                atualizacoes: [],

                isLogedIn: false,

                redirectWithUserSavedState: true,

                isEditing: false,

                listaConcorrentes: [],

                concorrentes: [],

                abortTeste: "abc",

                tipoDashboard: "Empregabilidade",

                namesCollections: {},

                areaDetalhadaSelecionadoCombo: false
            }
        case actionTypes.SET_LOGIN:
            return {
                ...state,
                isLogedIn: action.token
            }
        case actionTypes.SET_DATA_VAGA_SELECIONADA:
            return {    
                ...state,
                dataSelecionadoCombo: action.value
            }
        case actionTypes.SET_DATA_CAPTURADA:
            return {    
                ...state,
                dataCapturadaCombo: action.value
            }
        case actionTypes.SET_OCUPACAO_CAPTURADA:
            return {    
                ...state,
                ocupacaoCapturadaCombo: action.value
            }
        case actionTypes.SET_ESTADO_CAPTURADO:
            return {    
                ...state,
                estadoCapturadoCombo: action.value
            }
        case actionTypes.SET_MUNICIPIO_CAPTURADO:
            return {    
                ...state,
                municipioCapturadoCombo: action.value
            }
        case actionTypes.SET_TIPO_DASHBOARD:
            return {
                ...state,
                tipoDashboard: action.tipo
            }
        case actionTypes.SET_ANO_BASE:
            return {
                ...state,
                atualizacoes: action.list
            }
        case actionTypes.SET_CANCELAR_WIZARD:
            return {
                ...state,
                cancelarWizard: action.value,
                isWizard: false
            }
        case actionTypes.SET_USER_SAVED_STATES:
            return {
                ...state,
                userSavedStates: action.states
            }
        case actionTypes.SET_EXIBIR_BOTAO_VOLTAR_GERENCIAR_CURSOS:
            return{
                ...state,
                exibirBotaoVoltarGerenciarCursos: action.value
            }
        case actionTypes.SET_NAMES_COLLECTIONS:
            return {
                ...state,
                namesCollections: action.list
            }
        case actionTypes.CHECK_USER_SUPERADMIN:
            return {
                ...state,
                isSuperAdmin: action.value
            }
        default:
            return state;
    }
};

export default reducer;