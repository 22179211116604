import { axios } from './config/axios-config'

const deleteIncomplete = async (stateToDeleteObject = {}) => {
    try {
        const response = await axios.post('/user/data/deleteIncomplete', stateToDeleteObject)
    } 
    catch(e) {
        return true
    }
}

export default deleteIncomplete