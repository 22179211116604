import { axios, store, removeLoginIfLogedOut, abortController } from '../config/axios-config'
import { useQuery } from 'react-query'
import { defaultQueryConfig } from '../config/query-config'

// A separate function that just fetches and returns data
export async function fetchIESDataAll() {
    // Reset error flags in Redux
    store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: false })

    // Parallel requests
    const [responseIES, responseUserIES] = await Promise.all([
        axios.get('/backend/listaIES'),
        axios.get('/user/data/listaUserIES'),
    ])

    // Combine, sort, and return
    const combinedData = responseIES.data.concat(responseUserIES.data)
    combinedData.sort((a, b) => a.NO_IES.localeCompare(b.NO_IES))

    return {
        combinedData,
        userIESData: responseUserIES.data,
    }
}

export default function useIESDataAll() {
    return useQuery(
        'IESDataAll',
        fetchIESDataAll,
        {
            onSuccess: ({ combinedData, userIESData }) => {
                // Dispatch after fetching is successful
                store.dispatch({ type: 'SET_USER_IES_LIST', list: userIESData })
                store.dispatch({ type: 'SET_IES_LIST', list: combinedData })
            },
            onError: (error) => {
                removeLoginIfLogedOut(error)
                store.dispatch({ type: 'SET_LOADING', loading: false })
                store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: true })
            },
            ...defaultQueryConfig,
        }
    )
}
