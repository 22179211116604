import {store, axios} from './config/axios-config'
import {startLoadingPage, stopLoadingPage} from '../utils/LoadingPageController'
import getIndicadores from './getIndicadores'
import getIESIndicadores from './getIESIndicadores'
import getIndicadoresROI from './getIndicadoresROI'
import getRelatorioDetalhado from './getRelatorioDetalhado'
import getRelatorioDetalhadoIES from './getRelatorioDetalhadoIES'
import getUserStates from './getUserStates'
import getUsuarios from './getUsuarios'
import getPerfis from './getPerfis'
import getUserLocalOfertaData from './getUserLocalOfertaData'
import getUserCourseDataAll from './getUserCourseData'
import getInfoMunicipios from './getInfoMunicipios'
import getIESDataAll from './getIESData'
import getEnadeIndicadores from './getEnadeIndicadores'
import getFilteredUserState from "./getFilteredUserState";
//import verifyUserRole from './verifyUserRole'
import { queryClient } from './config/react-query-client'
import { fetchInfoMunicipios } from './Controller/useInfoMunicipios';
import {fetchUsuarios} from "./Controller/useUsuarios";
import {fetchIESDataAll} from "./Controller/useIESData";
import {fetchPerfis} from "./Controller/usePerfis";
import {fetchUserLocalOfertaData} from "./Controller/useUserLocalOfertaData";
import {fetchUserCourseDataAll} from "./Controller/useUserCourseDataAll";
import {fetchIndicadores} from "./Controller/useindicadores";
import {fetchRelatorioDetalhado} from "./Controller/useRelatorioDetalhado"; // Import the fetch function

const getAllData = async (object, salvarIndicadores=true, startStopLoadingPage=true, tipoChamada="Empregabilidade", actualState=false, areaDetalhada = false) => {

    setTimeout(() =>{store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: false })}, 0)
    // setTimeout(() =>{store.dispatch({ type: 'SET_LOADING', loading: true })}, 0)

    //await getUserStates();
    await getFilteredUserState();
    let indicadores
    const startTime = startStopLoadingPage ? startLoadingPage(store.dispatch) : 0
    try {

        if(!actualState) actualState = await store.getState()

        switch (tipoChamada) {
            case 'Empregabilidade':


                await queryClient.fetchQuery('infoMunicipios', fetchInfoMunicipios);
                await queryClient.fetchQuery('getUsuarios', fetchUsuarios);
                await queryClient.fetchQuery('IESDataAll', fetchIESDataAll);
                await queryClient.fetchQuery('getPerfis', fetchPerfis);
                //await queryClient.fetchQuery('userLocalOfertaData', fetchUserLocalOfertaData);
                //await queryClient.fetchQuery('userCourseDataAll', fetchUserCourseDataAll);

                // await getInfoMunicipios()
                // await getUsuarios()
                // await getIESDataAll()
                // await getPerfis()
                // await getUserLocalOfertaData()
                // await getUserCourseDataAll()

                indicadores = await queryClient.fetchQuery(['indicadores', object, false, areaDetalhada], () =>
                    fetchIndicadores(object, false, areaDetalhada)
                );
                await queryClient.fetchQuery(['relatorioDetalhado', object, areaDetalhada], () =>
                    fetchRelatorioDetalhado(object)
                );

                // indicadores = await getIndicadores(object, false, areaDetalhada)
                //await getRelatorioDetalhado(object?.anoSelecionado, object?.codOcupacoes, object?.estadoSelecionado, object?.municipioSelecionado, object?.cursoSelecionado, object?.grauEscolaridadeSelecionado, areaDetalhada)



                if(actualState?.custoTotal?.raw){
                    await getIndicadoresROI({
                        salarioGraduacao: indicadores?.indicadorSalarioMedio.mediaGraduados,
                        salarioMedio: indicadores?.indicadorSalarioMedio.mediaOcupacao,
                        salarioEnsinoMedio: indicadores?.indicadorSalarioMedio.mediaEnsinoMedio,
                        custo: actualState?.custoTotal.raw,
                        periodo: actualState?.custoTotal.periodo
                    })
                }
                break;
            case 'RelatorioDetalhado':
                indicadores = await queryClient.fetchQuery(['indicadores', object, false, areaDetalhada], () =>
                    fetchIndicadores(object, false, areaDetalhada)
                );
                await queryClient.fetchQuery(['relatorioDetalhado', object, areaDetalhada], () =>
                    fetchRelatorioDetalhado(object)
                );

                // indicadores = await getIndicadores(object, false, areaDetalhada)
                //await getRelatorioDetalhado(object?.anoSelecionado, object?.codOcupacoes, object?.estadoSelecionado, object?.municipioSelecionado, object?.cursoSelecionado, object?.grauEscolaridadeSelecionado, areaDetalhada)
                if(actualState?.custoTotal?.raw){
                    await getIndicadoresROI({
                        salarioGraduacao: indicadores?.indicadorSalarioMedio.mediaGraduados,
                        salarioMedio: indicadores?.indicadorSalarioMedio.mediaOcupacao,
                        salarioEnsinoMedio: indicadores?.indicadorSalarioMedio.mediaEnsinoMedio,
                        custo: actualState?.custoTotal.raw,
                        periodo: actualState?.custoTotal.periodo
                    })
                }
                break;   
            case 'InteligenciaCompetitiva':
                await getRelatorioDetalhadoIES(object?.anoSelecionado, object?.estadoSelecionado, object?.municipioSelecionado, object?.cursoSelecionado, object?.instituicaoSelecionada, object?.concorrenteSelecionado)   
                await getIESIndicadores(
                    {
                        anoSelecionado: object?.anoSelecionado,
                        estadoSelecionado: object?.estadoSelecionado,
                        municipioSelecionado: object?.municipioSelecionado,
                        cursoSelecionado: object?.cursoSelecionado,
                        instituicaoSelecionada: object?.instituicaoSelecionada,
                        concorrente: object?.concorrenteSelecionado
                    }
                )
                await getEnadeIndicadores(
                    {
                        cursoSelecionado: object?.cursoSelecionado,
                        instituicaoSelecionada: object?.instituicaoSelecionada,
                        concorrenteSelecionado: object?.concorrenteSelecionado,
                        anoSelecionado: object?.anoSelecionado
                    }
                )
                break;
            default:
                break;
        }

        if(salvarIndicadores){
            const saveIndicadores = await axios.put('/user/data/salvarIndicadores', {
                ocupacoes: object.codOcupacoes,
                curso: object.cursoSelecionado
            })
            if(saveIndicadores.status !== 200) throw new Error('Erro de conexão.')
        }
        
        
    } catch (error) {
        if (startStopLoadingPage) stopLoadingPage(startTime, store.dispatch)
        setTimeout(() =>{store.dispatch({ type: 'SET_LOADING', loading: false })}, 0)
        setTimeout(() =>{store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: true })}, 0)
        console.error(error)
        return false
    }
    
    if (startStopLoadingPage){
        stopLoadingPage(startTime, store.dispatch)
    } 
    store.dispatch({ type: 'SET_LOADING', loading: false })
    return true
}

export default getAllData