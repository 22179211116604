import { axios, store, removeLoginIfLogedOut, abortController } from '../config/axios-config';
import { useQuery } from 'react-query';
import { defaultQueryConfig } from '../config/query-config';
export async function fetchIndicadores(params, salvarIndicadores = true, areaDetalhada = false) {
    const { anoSelecionado, codOcupacoes, estadoSelecionado, municipioSelecionado, cursoSelecionado, grauEscolaridadeSelecionado } = params;

    // Ensure valid required parameters
    if (!anoSelecionado || codOcupacoes.length === 0 || !estadoSelecionado) {
        return null;
    }

    try {
        // Conditionally save indicators
        if (salvarIndicadores) {
            await axios.put('/user/data/salvarIndicadores', {
                ocupacoes: codOcupacoes,
                curso: cursoSelecionado
            });
        }

        // Make the API request
        const response = await axios.post('/backend/getIndicadores', {
            ano: anoSelecionado,
            ocupacoes: codOcupacoes,
            uf: estadoSelecionado,
            municipio: municipioSelecionado,
            curso: cursoSelecionado,
            grauEscolaridade: grauEscolaridadeSelecionado,
            areaDetalhada: areaDetalhada
        }, {
            cancelToken: abortController.token
        });

        // Update Redux state
        store.dispatch({ type: 'SET_INDICADORES', list: response.data });

        return response.data;
    } catch (error) {
        removeLoginIfLogedOut(error);
        throw new Error('Erro de conexão.');
    }
}

export default function useIndicadores(params, salvarIndicadores = true, areaDetalhada = false) {
    return useQuery(
        ['indicadores', params, salvarIndicadores, areaDetalhada], // Unique key for caching
        () => fetchIndicadores(params, salvarIndicadores, areaDetalhada),
        {
            enabled: !!params.anoSelecionado && params.codOcupacoes.length > 0 && !!params.estadoSelecionado,
            ...defaultQueryConfig
        }
    );
}
