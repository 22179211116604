import { axios, store, removeLoginIfLogedOut } from '../config/axios-config'
import { useQuery } from 'react-query'
import { defaultQueryConfig } from '../config/query-config'

export async function fetchUserCourseDataAll(ies, localOferta) {
    store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: false })

    try {
        const [responseCourses, responseTodosCursos] = await Promise.all([
            axios.post('/user/data/listaCursosUsuario', { ies, localOferta }),
            axios.get('/user/data/listaLocalOfertaUsuarioTodosCursos')
        ])

        store.dispatch({ type: 'GET_LIST_USER_COURSES', list: responseCourses.data })
        store.dispatch({ type: 'GET_LIST_LOCAL_OFERTA_USUARIO_TODOS_CURSOS', list: responseTodosCursos.data })

        return {
            userCourses: responseCourses.data,
            todosCursos: responseTodosCursos.data
        }
    } catch (error) {
        console.log("Erro na getCourseDataAll.")
        removeLoginIfLogedOut(error)
        store.dispatch({ type: 'SET_LOADING', loading: false })
        store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: true })
        throw error
    }
}

export default function useUserCourseDataAll(ies, localOferta) {
    console.log("useUserCourseDataAll", ies, localOferta)
    return useQuery(['userCourseDataAll', ies, localOferta], () => fetchUserCourseDataAll(ies, localOferta), defaultQueryConfig)
}
