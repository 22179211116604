import { axios, store, removeLoginIfLogedOut, abortController } from './config/axios-config'

const getInfoMunicipios = async () => {

    store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: false })
    try {

        let response = await axios.get('/backend/listaInfoMunicipios', {
            cancelToken: abortController.token
        })
        store.dispatch({ type: 'SET_INFO_MUNICIPIOS', list: response.data  })

    } catch (error) {
        removeLoginIfLogedOut(error)
        store.dispatch({ type: 'SET_LOADING', loading: false })
        store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: true })
    }
}

export default getInfoMunicipios