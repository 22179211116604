import { useQuery } from 'react-query';
import { axios, store, removeLoginIfLogedOut, abortController } from '../config/axios-config';
import { defaultQueryConfig } from '../config/query-config';

// Função responsável pela chamada à API
export async function fetchPerfis() {
    // Resetando flags antes da requisição
    store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: false });

    try {
        // Realizando a requisição
        const response = await axios.get('/user/roles', {
            cancelToken: abortController.token,
        });
        console.log(`getPerfis react-query`, response);

        // Retornando os dados
        return response.data;
    } catch (error) {
        console.log('getPerfis react-query error', error);
        // Lançar erro para o react-query manipular no `onError`
        throw error;
    }
}

// Hook customizado usando React Query
export default function usePerfis() {
    console.log("called [usePerfis]");
    return useQuery(
        'getPerfis', // Chave única para cache do react-query
        fetchPerfis, // Função responsável pela chamada
        {
            onSuccess: (data) => {
                // Verifica se a resposta contém permissões válidas
                console.log('usePerfis react-query', data);
                if (!Object.keys(data).includes('noPermisson')) {
                    store.dispatch({ type: 'SET_PERFIS', list: data });
                }
            },
            onError: (error) => {
                // Tratamento de erros
                console.log('usePerfis error react-query', error);
                removeLoginIfLogedOut(error);
                store.dispatch({ type: 'SET_LOADING', loading: false });
                store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: true });
            },
            ...defaultQueryConfig, // Configurações globais adicionais
        }
    );
}
