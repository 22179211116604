import { useQuery } from 'react-query';
import { axios, store, removeLoginIfLogedOut, abortController } from '../config/axios-config';
import { defaultQueryConfig } from '../config/query-config';

// Função responsável pela chamada à API.
export async function fetchUsuarios() {
    // Resetando flags antes da requisição
    store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: false });

    try {
        // Realizando a requisição
        const response = await axios.get('/user/getUsuarios', {
            cancelToken: abortController.token,
        });
        console.log(`getUsuarios react-query`, response)

        // Retornando os dados
        return response.data;
    } catch (error) {
        console.log('getUsuarios react-query', error)
        // Lançar erro para o react-query manipular no `onError`
        throw error;
    }
}

// Hook customizado usando React Query
export default function useUsuarios() {
    console.log("called [useUsuarios]");
    return useQuery(
        'getUsuarios', // Chave única para cache do react-query
        fetchUsuarios, // Função responsável pela chamada
        {
            onSuccess: (data) => {
                // Verifica se a resposta contém permissões válidas
                console.log('useUsuarios react-query', data)
                if (!Object.keys(data).includes('noPermisson')) {
                    store.dispatch({ type: 'SET_USUARIOS', list: data });
                }
            },
            onError: (error) => {
                // Tratamento de erros
                console.log('useUsuarios error react-query', error)
                removeLoginIfLogedOut(error);
                store.dispatch({ type: 'SET_LOADING', loading: false });
                store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: true });
            },
            ...defaultQueryConfig, // Configurações globais adicionais
        }
    );
}
