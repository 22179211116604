import { axios, store, removeLoginIfLogedOut, abortController } from './config/axios-config'
import deleteIncomplete from "./deleteIncomplete";

const getFilteredUserState = async () => {

    setTimeout(() =>{store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: false })}, 0)

    console.log('getFilteredUserState', 'starting')
    try {
     
        let response = await axios.get('/user/data/filteredSavedUserState',{
            cancelToken: abortController.token
        })
        let aux = response.data.filter(obj => obj.state.instituicaoSelecionada != "")

        console.log('getFilteredUserState', response.data, aux)

        let toDeleteList = response.data.filter(obj => obj.state.instituicaoSelecionada == "")
        if (toDeleteList.length > 0) {
            for (let toDeletObj of toDeleteList) {
                console.log('delete', toDeletObj)
                deleteIncomplete(toDeletObj)
            }
        }




        // Criando um novo campo no Redux
        // store.dispatch({ type: 'SET_USER_SAVED_STATES_FILTERED', states: response.data })
        // store.dispatch({ type: 'SET_USER_SAVED_STATES', states: response.data })

        store.dispatch({ type: 'SET_USER_SAVED_STATES_FILTERED', states: aux })
        store.dispatch({ type: 'SET_USER_SAVED_STATES', states: aux })

        //return response.data
        return aux
        
        
    } catch (error) {
        console.log("getFilteredUserState", error)
        removeLoginIfLogedOut(error)
        store.dispatch({ type: 'SET_LOADING', loading: false })
        store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: true })
    }

}

export default getFilteredUserState