import { useQueryClient } from 'react-query';
import { axios, store, removeLoginIfLogedOut, abortController } from '../config/axios-config';

// Fetch function for Relatório Detalhado (used inside query and imperative calls)
export async function fetchRelatorioDetalhado({ anoSelecionado, codOcupacoes, estadoSelecionado, municipioSelecionado, cursoSelecionado, grauEscolaridadeSelecionado, areaDetalhada = false }) {
    if (!anoSelecionado || !codOcupacoes.length || !estadoSelecionado) {
        throw new Error('Parâmetros inválidos para buscar o Relatório Detalhado.');
    }

    try {
        const response = await axios.post('/backend/getRelatorioDetalhado', {
            ano: anoSelecionado,
            ocupacoes: codOcupacoes,
            uf: estadoSelecionado,
            municipio: municipioSelecionado,
            curso: cursoSelecionado,
            grauEscolaridade: grauEscolaridadeSelecionado,
            areaDetalhada: areaDetalhada
        }, {
            cancelToken: abortController.token
        });

        console.log("relatorioDetalhado", response);
        store.dispatch({ type: 'SET_RELATORIO_DETALHADO', list: response.data });

        return response.data;
    } catch (error) {
        console.error("relatorioDetalhado error", error);
        removeLoginIfLogedOut(error);
        throw new Error('Erro de conexão.');
    }
}

// Hook for automatically fetching Relatório Detalhado in a React component
export default function useRelatorioDetalhado(params) {
    const queryClient = useQueryClient();

    return queryClient.fetchQuery(
        ['relatorioDetalhado', params],
        () => fetchRelatorioDetalhado(params)
    );
}
