import { QueryClient } from 'react-query';

// Define the QueryClient instance
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 60 * 60 * 1000,       // 1 hour
            cacheTime: 24 * 60 * 60 * 1000,  // 24 hours
            refetchOnWindowFocus: false,     // Do not refetch on window focus
            refetchOnReconnect: false,       // Do not refetch on reconnect
        },
    },
});
