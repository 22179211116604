import {axios, store, removeLoginIfLogedOut, abortController} from '../config/axios-config'
import { useQuery } from 'react-query'
import { defaultQueryConfig } from '../config/query-config'

export async function fetchUserLocalOfertaData(ies) {
    store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: false })

    try {
        const [responseLocalOferta, responseTodosCursos] = await Promise.all([
            axios.post('/user/data/listaLocalOfertaUsuario', ies, {cancelToken: abortController.token}),
            axios.get('/user/data/listaLocalOfertaUsuarioTodosCursos', {cancelToken: abortController.token})
        ])

        store.dispatch({ type: 'GET_LIST_LOCAL_OFERTA_USUARIO', list: responseLocalOferta.data })
        store.dispatch({ type: 'GET_LIST_LOCAL_OFERTA_USUARIO_TODOS_CURSOS', list: responseTodosCursos.data })

        console.log("debug useUserLocalOfertaData", ies, responseLocalOferta, responseTodosCursos)

        return {
            localOferta: responseLocalOferta.data,
            todosCursos: responseTodosCursos.data
        }


    } catch (error) {
        removeLoginIfLogedOut(error)
        store.dispatch({ type: 'SET_LOADING', loading: false })
        store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: true })
        throw error
    }
}

export default function useUserLocalOfertaData(ies) {
    return useQuery(['userLocalOfertaData', ies], () => fetchUserLocalOfertaData(ies), defaultQueryConfig)
}
